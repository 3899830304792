
import Button from "@/components/base/Button"
import { routeToBusiness, routeToBusinessSite } from "@/helpers/routing"
import { encodeString } from "@/helpers/utils"

export default {
    name: "BookSlotButton",
    components: { Button },
    props: {
        business: {
            type: Object,
            required: true,
        },
        schedule: {
            type: Array,
            default: () => [],
        },
        /** Title of the event (e.g. fair) */
        title: {
            type: String,
            default: null,
        },
    },
    computed: {
        routeToBusiness() {
            let route
            if (this.business.is_site_management_enabled) {
                route = routeToBusinessSite(this.business)
            } else {
                route = routeToBusiness(this.business)
            }
            if (this.title) {
                route.query = {
                    ...route.query,
                    title: this.title,
                }
            }
            if (this.schedule && this.schedule.length > 0) {
                route.query = {
                    ...route.query,
                    schedule: encodeString(JSON.stringify(this.schedule)),
                }
            }
            if (route) {
                route.hash = "#book-slot-section"
                const resolved = this.$router.resolve(route)
                if (resolved) return resolved.href
            }
            return ""
        },
    },
}
