// Seeded random number generator
class SeededRandom {
    constructor(seed) {
        this.seed = seed
    }

    random() {
        const x = Math.sin(this.seed++) * 10000
        return x - Math.floor(x)
    }
}

// Custom compare function using seeded random number generator
function seededRandomSort(a, b, seededRandom) {
    return seededRandom.random() - 0.5
}

function sortArrayWithSeed(array, seed) {
    const seededRandom = new SeededRandom(seed)
    return array.sort((a, b) => seededRandomSort(a, b, seededRandom))
}

function encodeString(str) {
    let buff = Buffer.from(str, "utf-8")
    return buff.toString("base64")
}

function decodeString(base64) {
    let buff = Buffer.from(base64, "base64")
    return buff.toString("utf-8")
}

export { encodeString, decodeString, sortArrayWithSeed }
